import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { fetchReportData, downloadReportToc } from "redux/actions/report";
import { RootState } from "redux/reducers";
import { isSubscribed } from "utils/subscriptions.utils";

import ReportHeader from "./ChildComponents/Header/ReportHeader";
import ReportMain from "./ChildComponents/Content/ReportMain";
import ReportSidebar from "./ChildComponents/Sidebar/ReportSidebar";
import ReportMenu from "./ChildComponents/Menu/ReportMenu";
import ReportFooter from "./ChildComponents/ReportFooter";
import Spinner from "../../Common/misc/Spinner";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const Report: React.FC = () => {
  const dispatch = useDispatch();
  const { id = "" } = useParams<{ id: string }>();
  const { currentUser } = useAuth();
  const { subscriptions, report } = useSelector((state: RootState) => state);

  const [loading, setLoading] = useState<boolean>(true);
  const [toggleLeftSide, setToggleLeftSide] = useState<boolean>(true);
  const [toggleRightSide, setToggleRightSide] = useState<boolean>(true);

  const subscribed = useMemo(
    () => isSubscribed(subscriptions, ~~id, currentUser, "active"),
    [subscriptions, id, currentUser]
  );

  useEffect(() => {
    if (!subscribed) {
      dispatch(downloadReportToc(id, setLoading));
    }
  }, [subscribed, dispatch, id]);

  useEffect(() => {
    if (id !== report.reportMeta.report_id) {
      dispatch(fetchReportData(id, currentUser.usergroupData.id, setLoading));
    }
  }, [dispatch, id, report.reportMeta.report_id, currentUser.usergroupData.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [report.currentChapter]);

  useEffect(() => {
    if (window.innerWidth > 1000 && window.innerWidth <= 1500) {
      setToggleRightSide(false);
    } else if (window.innerWidth <= 1000) {
      setToggleLeftSide(false);
      setToggleRightSide(false);
    }
  }, []);

  return loading ? (
    <>
      <Header />
      <Spinner />
      <Footer />
    </>
  ) : (
    <>
      <Header />
      <Container>
        <ReportHeader
          subscribed={subscribed}
          toggleLeftSide={toggleLeftSide}
          setToggleLeftSide={setToggleLeftSide}
          toggleRightSide={toggleRightSide}
          setToggleRightSide={setToggleRightSide}
        />
        <InnerContainer id={"InnerContainer"}>
          <ReportMenu
            subscribed={subscribed}
            toggleLeftSide={toggleLeftSide}
            setToggleLeftSide={setToggleLeftSide}
          />
          <ReportMain
            subscribed={subscribed}
            usergroup={currentUser.usergroupData}
          />
          <ReportSidebar
            reportId={+id}
            toggleRightSide={toggleRightSide}
            setToggleRightSide={setToggleRightSide}
          />
        </InnerContainer>
        <ReportFooter scrollPositions={report.search.scrollPositions} />
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  color: #000;
  min-height: 100vh;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: max-content auto max-content;
`;

export default Report;
