import React, { Suspense } from "react";
import styled from "styled-components";

import Spinner from "components/Common/misc/Spinner";
import CatalogFilterButtons from "./ChildComponents/CatalogFilterButtons";
import RequestConsultationButton from "./ChildComponents/RequestConsultationButton";
import CatalogCategoryFilter from "./ChildComponents/CatalogCategoryFilter";
import MapFilter from "./ChildComponents/Filters/MapFilter";
import { FilterType } from "types/catalog";
import MedSKUCategoryFilter from "./ChildComponents/MedSKUCategoryFilter";
import PTCategoryFilter from "./ChildComponents/PTCategoryFilter";
import SurveyCategoryFilter from "./ChildComponents/SurveyCategoryFilter";
import MapFilterList from "./ChildComponents/Filters/MapFilterList";

interface CatalogSkeletonProps {
  filters: FilterType[];
  menu: any;
  list: React.ReactNode;
  hideFilters: boolean;
  parent: string;
  sectionName: string;
}

const CatalogSkeleton = ({
  filters,
  menu,
  list,
  hideFilters,
  parent,
  sectionName,
}: CatalogSkeletonProps) => {
  let categoryFilter = (
    <CatalogCategoryFilter
      menu={menu}
      hidden={hideFilters}
      sectionName={sectionName}
    />
  );

  if (parent === "medsku") {
    categoryFilter = (
      <MedSKUCategoryFilter
        menu={menu}
        hidden={hideFilters}
        sectionName={sectionName}
      />
    );
  }
  if (parent === "pt") {
    categoryFilter = (
      <PTCategoryFilter
        menu={menu}
        hidden={hideFilters}
        sectionName={sectionName}
      />
    );
  }
  if (parent === "survey") {
    categoryFilter = (
      <SurveyCategoryFilter
        menu={menu}
        hidden={hideFilters}
        sectionName={sectionName}
      />
    );
  }
  return (
    <Container>
      <Buttons>
        <CatalogFilterButtons filtersData={filters} parent={parent} />
        <h1>{sectionName}</h1>
        <RequestConsultationButton />
      </Buttons>
      {categoryFilter}
      <MapFilter hidden={hideFilters} />
      <MapFilterList hidden={hideFilters} />
      <Suspense fallback={<Spinner />}>{list}</Suspense>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: auto;
  grid-template-areas:
    "tabs tabs tabs tabs tabs tabs tabs"
    "cat  cat  cat  cat  cat  map  map"
    "rl   rl   rl   rl   rl   rl   rl ";
  padding: 2rem;
  grid-gap: 2rem;

  @media screen and (max-width: 1000px) {
    grid-template-areas:
      "tabs tabs tabs tabs tabs tabs tabs"
      "cat  cat  cat  cat  cat  cat  cat"
      "map  map  map  map  map  map  map"
      "rl   rl   rl   rl   rl   rl   rl ";
    grid-auto-rows: min-content minmax(30rem, 40rem) auto auto;
  }
`;

const Buttons = styled.div`
  grid-area: tabs;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin: 0;
  }

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: center;

    > button, h1 {
      margin: 1rem auto;
    }
  }
`;

export default CatalogSkeleton;
