import React from "react";
import styled from "styled-components";
import upArrow from "assets/icons/arrow-up.svg";

import FooterReportNavigator from "./FooterReportNavigator";

let contentRightSide;

function reportWindowSize() {
  contentRightSide = document
    ?.getElementById("reportContent")
    ?.getBoundingClientRect();

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  if (vw > 1000 && contentRightSide !== undefined) {
    document
      .getElementById("reportFooter")
      ?.setAttribute("style", `left: ${contentRightSide.right}px`);
  } else {
    // document.getElementById("reportFooter")?.removeAttribute("style");
    document
      .getElementById("reportFooter")
      ?.setAttribute("style", `left: ${0}px`);
  }
}

window.addEventListener("resize", reportWindowSize);
window.addEventListener("scroll", reportWindowSize);

const ReportFooter: React.FC<{ scrollPositions }> = ({ scrollPositions }) => {
  reportWindowSize();

  return (
    <Container id="reportFooter">
      <ReportNavigatorDiv>
        {scrollPositions.length > 0 && <FooterReportNavigator />}
      </ReportNavigatorDiv>
      <BTTLink onClick={() => window.scrollTo(0, 0)}>
        <UpArrow src={upArrow} alt="up arrow" />
        Top
      </BTTLink>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 2rem;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
`;

const BTTLink = styled.button`
  color: ${(props) => props.theme.colors.idataDarkBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  background-color: ${(props) => props.theme.colors.grey};
  padding-bottom: 1.5rem;
  pointer-events: auto;

  &:hover {
    opacity: 0.9;
  }

  @media screen and (max-width: 1305px) {
    margin: 0;
    margin-right: 2rem;
  }
`;

const UpArrow = styled.img`
  margin-bottom: -1rem;
`;

const ReportNavigatorDiv = styled.div`
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
  }
`;
export default ReportFooter;
