import React from "react";
import styled from "styled-components";

import PageHeader from "../../Common/misc/PageHeader";
import Notification from "components/Common/misc/Notification";
import ContactForm from "./ChildComponents/ContactForm";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const Contact: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Notification />
        <InnerContainer>
          <PageHeader>Contact Us</PageHeader>
          <FormWrapper>
            <ContactForm />
          </FormWrapper>
        </InnerContainer>
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  color: #000;
  min-height: 100vh;
  width: 100%;
`;

const InnerContainer = styled.div`
  max-width: ${(props) => props.theme.bp.xxl}px;
  margin: auto;
  padding: 5rem;
  padding-top: 3rem;
  width: 100%;
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    padding: 2rem;
  }
`;

const FormWrapper = styled.div`
  max-width: 75rem;
  margin: 10rem auto;
`;

export default Contact;
