import React from "react";
import styled from "styled-components";
import { NewsItem as NewsItemType } from "types";

const NewsItem: React.FC<NewsItemType> = ({
  title,
  link,
  pubDate,
  contentSnippet,
}) => {
  let date = "";

  if (pubDate !== "1000-01-01T08:12:28.000Z") {
    date = new Date(pubDate).toLocaleString("en-US", {
      dateStyle: 'full',
      timeStyle: 'short'
    });
  }

  return (
    <Container>
      <a href={link} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
      <PubDate>{date}</PubDate>
      <ContentSnippet>{contentSnippet}</ContentSnippet>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 1em;
`;

const PubDate = styled.div`
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 0.9em;
`;

const ContentSnippet = styled.p`
  // Show only 3 lines of text
  // https://stackoverflow.com/a/13924997
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;

  margin: 0.5em 0;
`;
export default NewsItem;
