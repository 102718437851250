import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Modal from "react-modal";

import { RootState } from "redux/reducers";
import { modalStyles } from "styles/modals";

import BuyReport from "components/Common/forms/BuyReport";
import FavoriteButton from "components/Common/buttons/FavoriteButton";
import DashboardButton from "components/Common/buttons/DashboardButton";
import ReportSearch from "./ReportSearch";
import { generateReportUrl } from "utils/report.utils";
import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";
import { useAuth } from "contexts/AuthContext";

import arrowLeftIcon from "assets/icons/arrow-left-long.svg";
import { ReactComponent as LeftSidebarIcon } from "assets/icons/interface-layout-left-sidebar-icon.svg";
import { ReactComponent as RightSidebarIcon } from "assets/icons/interface-layout-right-sidebar-icon.svg";

Modal.setAppElement("#root");

type ToggleLeftProps = {
  toggleLeftSide?: boolean;
};
type ToggleRightProps = {
  toggleRightSide?: boolean;
};
type T = {
  subscribed: boolean;
  toggleLeftSide: boolean;
  setToggleLeftSide: Function;
  toggleRightSide: boolean;
  setToggleRightSide: Function;
};

const ReportHeader: React.FC<T> = ({
  subscribed,
  toggleLeftSide,
  setToggleLeftSide,
  toggleRightSide,
  setToggleRightSide,
}) => {
  const { currentUser } = useAuth();
  const [toggleBuyModal, setToggleBuyModal] = useState(false);
  const [upgradeable, setUpgradeable] = useState(false);
  const [subbedToLatest, setSubbedToLatest] = useState(false);
  const [latestReportYear, setLatestReportYear] = useState(0);
  const [reportId, setReportId] = useState(0);
  const { report, subscriptions } = useSelector((state: RootState) => state);
  const reportMeta = report.reportMeta;
  const title = subscribed ? reportMeta : report.unsubscribed.content;
  const url = generateReportUrl(report.reportMeta.report_name);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setReportId(report.reportMeta.report_id);
  }, [report.reportMeta.report_id]);

  useEffect(() => {
    axios
      .post(`/reports/upgrade`, {
        title: title.report_name,
      })
      .then((res) => {
        if (res.data) {
          const similarReports = res.data;

          if (!upgradeable && similarReports.length > 1) {
            // See if there are any reports newer than report.reportMeta.report_year
            // Sort reportsWithSameName by report_year
            similarReports.sort((a, b) => b.report_year - a.report_year);

            setLatestReportYear(similarReports[0].report_year);
            // If first report_year is greater than the current report year
            if (similarReports[0].report_year > report.reportMeta.report_year) {
              // Check to see if user is already subscribed to latest report
              const latestSub = subscriptions.active.find((sub) => {
                return (
                  sub.report_name === title.report_name &&
                  sub.report_year === similarReports[0].report_year
                );
              });

              if (!latestSub) {
                // Show upgrade button
                setUpgradeable(true);
              } else {
                // Notify user that they are subscribed to latest revision of report
                if (subscribed) setSubbedToLatest(true);
              }
            }
          }
        }
      });
  }, [
    report.reportMeta.report_year,
    subscribed,
    subscriptions.active,
    title.report_name,
    upgradeable,
  ]);

  function updateJson() {
    dispatch(
      setNotification(
        {
          message: "Updating report...",
          variant: NotificationVariant.primary,
        },
        false
      )
    );
    axios
      .patch(`/reports/update-json/${report.reportMeta.report_id}`)
      .then((res) => {
        dispatch(
          setNotification(
            {
              message:
                "Report updated! Refresh the page to see the updated report",
              variant: NotificationVariant.success,
            },
            false
          )
        );
      });
  }

  return (
    <Container id={"ReportHeader"}>
      <LeftSection>
        <LeftSidebarButton onClick={() => setToggleLeftSide(!toggleLeftSide)}
          toggleLeftSide={toggleLeftSide}>
          <LeftSidebarIcon />
        </LeftSidebarButton>

        <BackLink to={location.state ? "/catalog/search" : "/catalog"}>
          <img src={arrowLeftIcon} alt="back icon" />
          <div>{location.state ? "Results" : "Catalog"}</div>
        </BackLink>
      </LeftSection>

      <HeadingDiv>
        <Heading>{title.report_name}</Heading>
        <small hidden={!subbedToLatest}>
          This is the {title.report_year} revision, but you are also subscribed
          to the latest {latestReportYear} revision.
        </small>
      </HeadingDiv>

      <RightSection>
        <Button
          hidden={upgradeable && subscribed && !subbedToLatest}
          onClick={() => setToggleBuyModal(true)}
        >
          <NewIcon>!</NewIcon> Upgrade to {latestReportYear}
        </Button>

        <UpdateButton
          hidden={currentUser.role === "admin"}
          onClick={() => updateJson()}
        >
          Update Report
        </UpdateButton>

        <DashboardButton
          global_dashboard={title.global_dashboard}
          id={reportId}
          url={url}
          subscribed={subscribed}
        />

        <FavoriteButton
          reportId={reportMeta.report_id}
          c_report_id={report.c_report_id}
          medCore={report.c_report_id ? true : false}
        />

        <ReportSearch />

        <RightSidebarButton onClick={() => setToggleRightSide(!toggleRightSide)}
          toggleRightSide={toggleRightSide}>
          <RightSidebarIcon />
        </RightSidebarButton>
      </RightSection>

      <BottomSection>
        <LeftSidebarButton onClick={() => setToggleLeftSide(!toggleLeftSide)}
          toggleLeftSide={toggleLeftSide}>
          <LeftSidebarIcon />
        </LeftSidebarButton>

        <MiddleSection>
          <ButtonsDiv>
            <BackLink to={location.state ? "/catalog/search" : "/catalog"}>
              <img src={arrowLeftIcon} alt="back icon" />
              <div>{location.state ? "Results" : "Catalog"}</div>
            </BackLink>

            <Button
              hidden={upgradeable && subscribed && !subbedToLatest}
              onClick={() => setToggleBuyModal(true)}
            >
              <NewIcon>!</NewIcon> Upgrade to {latestReportYear}
            </Button>

            <UpdateButton
              hidden={currentUser.role === "admin"}
              onClick={() => updateJson()}
            >
              Update Report
            </UpdateButton>

            <DashboardButton
              global_dashboard={title.global_dashboard}
              id={reportId}
              url={url}
              subscribed={subscribed}
            />

            <FavoriteButton
              reportId={reportMeta.report_id}
              c_report_id={report.c_report_id}
              medCore={report.c_report_id ? true : false}
            />
          </ButtonsDiv>

          <ReportSearch />
        </MiddleSection>

        <RightSidebarButton onClick={() => setToggleRightSide(!toggleRightSide)}
          toggleRightSide={toggleRightSide}>
          <RightSidebarIcon />
        </RightSidebarButton>
      </BottomSection>

      <Modal
        onRequestClose={() => setToggleBuyModal(false)}
        isOpen={toggleBuyModal}
        contentLabel="Buy Report"
        style={modalStyles}
      >
        <BuyReport
          setToggleModal={setToggleBuyModal}
          report_name={title.report_name}
          report_year={latestReportYear.toString()}
          report_id={title.report_id}
        />
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.darkGrey};
  background-color: #f6f8f9;

  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  padding: 1rem;
  z-index: 300;

  @media screen and (max-width: 1150px) {
    display: block;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

const HeadingDiv = styled.div`
  margin: 0 1rem;

  @media screen and (max-width: 1150px) {
    margin: 0;
    text-align: center;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: 1rem;
    border-bottom: 2px solid ${(props) => props.theme.colors.darkGrey};
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;

  button, form {
    margin-left: 1rem;
  }

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

const BottomSection = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1150px) {
    display: flex;
  }
`;

const MiddleSection = styled.div`
  display: flex;
  align-items: center;

  form {
    margin-right: 1rem;
  }

  @media screen and (max-width: 700px) {
    display: block;
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem;
  
  button {
    margin: 0 1rem;
  }
  
  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
    justify-content: space-evenly;

    button {
      margin-bottom: 1rem;
    }
  }
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0 1rem;

  img {
    margin-right: 1rem;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: 400;
  margin: 0;
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.yellow};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 1.8rem;
  padding: 1rem 2rem;
  height: 4rem;

  display: ${(props) => (props.hidden ? "flex" : "none")};

  &:hover {
    opacity: 0.8;
  }
`;

const NewIcon = styled.span`
  background-color: ${(props) => props.theme.colors.yellow};
  border: 1px solid #000;
  border-radius: 50%;
  display: inline-block;
  color: #000;
  font-size: 2.5rem;
  line-height: 1;
  margin-right: 1.5rem;
  height: 2.5rem;
  width: 2.5rem;
`;

const UpdateButton = styled.button`
  background-color: ${(props) => props.theme.colors.idataBlue};
  color: white;
  margin: 0 1rem;
  padding: 1rem 2rem;
  display: ${(props) => (props.hidden ? "block" : "none")};

  &:hover {
    opacity: 0.8;
  }
`;

const LeftSidebarButton = styled.button<ToggleLeftProps>`
  background-color: ${(props) => (props.toggleLeftSide ?
    props.theme.colors.idataBlue : props.theme.colors.darkGrey)};
  padding: 1rem;

  svg {
    width: 3rem;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(52deg) brightness(101%) contrast(102%);
  }
`;

const RightSidebarButton = styled.button<ToggleRightProps>`
  background-color: ${(props) => (props.toggleRightSide ?
    props.theme.colors.idataBlue : props.theme.colors.darkGrey)};
  padding: 1rem;

  svg {
    width: 3rem;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(52deg) brightness(101%) contrast(102%);
  }
`;

export default ReportHeader;
