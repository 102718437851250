import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import searchIcon from "assets/icons/Search-Icon-White.svg";
import { searchDocuments } from "redux/actions/search";
import { clearErrors } from "redux/actions/errors";
import { RootState } from "redux/reducers";

type SearchBarProps = {
  hidden: boolean;
};

const SearchBar: React.FC<SearchBarProps> = ({ hidden }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errors = useSelector((state: RootState) => state.errors);
  const [query, setQuery] = useState<string>("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(clearErrors());

    const body = {
      query,
    };

    dispatch(searchDocuments(body, navigate));
  };

  if (hidden) {
    return null;
  } else {
    return (
      <Container autoComplete="off" onSubmit={handleSubmit}>
        <Input
          id="searchbar"
          type="text"
          name="query"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          placeholder="Search All Reports"
        />
        <SearchButton type="submit">
          <img src={searchIcon} alt="search glass" />
        </SearchButton>
        <Error>{errors.query}</Error>
      </Container>
    );
  }
};

const Container = styled.form`
  align-self: center;
  background-color: ${(props) => props.theme.colors.lightBlue};
  border-radius: 5rem;
  display: flex;
  position: relative;

  @media screen and (max-width: 1290px) {
    display: none;
  }
`;

const Input = styled.input`
  background: transparent;
  color: #fff;
  font-size: 2rem;
  padding: 1rem 2rem;
  padding-right: 0;
  height: 4rem;
  width: 100%;

  &::placeholder {
    color: #fff;
  }

  @media screen and (max-width: 1290px) {
    display: none;
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
  position: absolute;
  top: 29px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
`;

const SearchButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  height: 4rem;
  width: 4rem;
`;

export default SearchBar;
