import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "contexts/AuthContext";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import styled from "styled-components";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();

  if (currentUser && (currentUser.role === "sales" || currentUser.role === "admin")) {
    return (
      <>
        <Header />
        <Container>
          <Outlet />
        </Container>
        <Footer />
      </>
    );
  }

  return (
    currentUser && currentUser.enabled ? <Outlet/> : <Navigate to="/" />
  )
}

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  color: #000;
  min-height: 90vh;
  width: 100%;
  padding: 1rem;
`;

export default PrivateRoutes;

