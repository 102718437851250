import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "contexts/AuthContext";

import { ReportMeta } from "types/reports";

import { RootState } from "redux/reducers";
import { fetchRecommendations } from "redux/actions/report";

import RecModeButton from "./ChildComponents/RecModeButton";
import RecommendedReportItem from "./ChildComponents/RecommendedReportItem";

type T = {
  reportId: number;
};

const RecommendedReports: React.FC<T> = ({ reportId }) => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  const { reportMeta, unsubscribed } = useSelector((state: RootState) => state.report);
  const { recommendations } = useSelector((state: RootState) => state.report);

  useEffect(() => {
    dispatch(fetchRecommendations(reportId));
  }, [dispatch, reportId]);

  return (
    <Container>
      <Header>
        <div>
          Recommended Reports
          <RecModeButton hidden={currentUser.role === "admin" ? false : true} />
        </div>
      </Header>
      <Items>
        {recommendations.map((report: ReportMeta) => {
          return <RecommendedReportItem key={report.report_id} report={report} />;
        })}
      </Items>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  margin: 1rem 0;
  height: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  position: sticky;
  top: 0;
  z-index: 10;
  margin-bottom: 1rem;

  div {
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.grey};
    border: 0;
    border-radius: 5rem;
    color: ${(props) => props.theme.colors.idataDarkGrey};
    font-size: 2.5rem;
    padding: 1rem 2rem;
    justify-content: space-between;
  }

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    div {
      height: 6rem;
    }
  }
`;

const Items = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

export default RecommendedReports;
