import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import StandardButton from "components/Common/buttons/StandardButton";
import zbIcon from "assets/logos/Zimmer_Biomet_logo.svg";

import firebase from "firebase/app";
import "firebase/auth";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";

var provider = new firebase.auth.SAMLAuthProvider('saml.zimmer-biomet');

const ZimmerBiometSignIn: React.FC = () => {
  // const [message, setMessage] = useState<string>("");

  const navigate = useNavigate();

  const { currentUser } = useAuth();

  // useEffect(() => {
  //   firebase.auth().getRedirectResult()
  //   .then((result) => {
  //     console.log(result);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     // if (error.message.includes("PERMISSION_DENIED")) {
  //     //   setMessage("Your account is not approved to sign in to iData Online. Please contact iData Research to get it approved.");
  //     // }
  //   });
  // }, []);

  useEffect(() => {
    if (currentUser?.enabled === true) {
      navigate("/catalog/subscriptions");
    }
  }, [currentUser])

  const handleSignIn = () => {
    firebase.auth().signInWithPopup(provider)
    .then((result) => {
      // console.log(result);
      navigate("/catalog/subscriptions");
    })
    .catch((error) => {
      console.error(error);
      // if (error.message.includes("PERMISSION_DENIED")) {
      //   setMessage("Your account is not approved to sign in to iData Online. Please contact iData Research to get it approved.");
      // }
    });
  }
  
  return (
    <>
      <Header />
      <Container>
      <Logo src={zbIcon} alt="Zimmer Biomet logo" />

        <StandardButton 
          onClick={handleSignIn}
          type="button"
        >
          Sign In with Zimmer Biomet
        </StandardButton>
        {/* <p>{message}</p> */}
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  min-height: 87vh;
  width: 40rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  margin-bottom: 1rem;
`;

export default ZimmerBiometSignIn;
