import React from "react";
import styled from "styled-components";

import logo from "assets/logos/logo.png";

const Logo: React.FC = () => (
  <Container>
    <a href="/catalog/all-reports">
      <LogoImg src={logo} alt="idata online logo" />
    </a>
  </Container>
);

const Container = styled.div`
  margin: auto 0;
  padding: 1rem 0;
`;

const LogoImg = styled.img`
  display: block;
  width: 25rem;

  @media screen and (max-width: 350px) {
    width: 100%;
  }
`;

export default Logo;
