import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { MenuItem as MenuItemType } from "types";
import { User as UserType } from "types/firebase";
import { setFilters, resetFilters } from "redux/actions/catalog";
import arrowDown from "assets/icons/arrow-down.svg";
import defaultProfilePicture from "assets/images/Default-Profile-Picture.png";
import { setErrors } from "redux/actions/errors";
import { toggleRecommending } from "redux/actions/admin";
import { RootState } from "redux/reducers";

type MenuItemProps = {
  menu: MenuItemType;
  isDropdown?: boolean;
  user?: UserType;
};

type ContainerProps = {
  readonly toggleDropdown?: boolean;
  readonly accountItem?: boolean;
};

const MainMenuItem: React.FC<MenuItemProps> = ({ menu, isDropdown }) => {
  const location = useLocation();
  const { currentUser, logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { recommending } = useSelector((state: RootState) => state.admin);
  const [toggleDropdown, setToggleDropdown] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  // Function from CatalogMenuItem.tsx
  const handleClick = async (e: any, key: string) => {
    dispatch(resetFilters());
    if (key === "sign-out") {
      if (recommending) dispatch(toggleRecommending());

      try {
        await logout();
      } catch {
        return dispatch(setErrors({ message: "Error while logging out" }));
      }

      navigate("/sign-out");
    } else if (key === "my-subscriptions") {
      dispatch(setFilters("subscribed", true));
    } else if (key === "my-favorites") {
      dispatch(setFilters("category", e.target.text));
    }
  };

  const handleAccess = () => {
    return (
      isDropdown &&
      currentUser &&
      menu.childAccess &&
      menu.childAccess.includes(currentUser.role)
    );
  };

  const renderMenuItems = (menu: MenuItemType["childMenu"], user: UserType) => {
    if (!menu) return null;

    return menu.map((child) =>
      child.access.includes(user.role) ? (
        <li key={child.key}>
          <DropdownItem
            to={child.path}
            onClick={(e) => handleClick(e, child.key)}
          >
            {child.name}
          </DropdownItem>
        </li>
      ) : null
    );
  };

  useEffect(() => {
    if (location.pathname.split('/')[1].includes(menu.key)) {
      setActive(true);
		} else if (location.pathname.includes('catalog') && menu.key === 'report') {
      setActive(true);
    } else {
			setActive(false);
		}
  }, [location.pathname, menu.name]);

  if (menu.external) {
    return (
      <Container>
        <ExtItemLink href={menu.path} target="_blank" rel="noopener noreferrer">
          {menu.name}
        </ExtItemLink>
      </Container>
    );
  } else if (menu.name === "Translate") {
    return (
      <TranslateDiv id="translate">
        <div id="google_translate_element"></div>
      </TranslateDiv>
    );
  } else {
    return (
      <Container
        toggleDropdown={toggleDropdown}
        onMouseOver={() => setToggleDropdown(true)}
        onMouseLeave={() => setToggleDropdown(false)}
        accountItem={menu.key === "signed-in-user"}
      >
        <ItemLink to={menu.path} className="main-link" $underline={active}>
          {menu.key === "signed-in-user" && (
            <ProfilePicture
              src={defaultProfilePicture}
              alt="profile portrait"
            />
          )}
          {menu.key === "signed-in-user" ? currentUser.name : menu.name}{" "}
          {handleAccess() && <DownArrow src={arrowDown} alt="down arrow" />}
        </ItemLink>
        {handleAccess() && (
          <Dropdown toggleDropdown={toggleDropdown}>
            {renderMenuItems(menu.childMenu, currentUser)}
          </Dropdown>
        )}
      </Container>
    );
  }
};

const accountItemStyles = css`
  cursor: pointer;
  padding: 2rem 0;

  a.main-link {
    border-left: 1px solid #fff;
    margin-left: 1.5rem;
    padding-left: 2rem;
  }

  ul {
    right: 0;
  }

  @media screen and (max-width: ${(props) => props.theme.bp.xl}px) {
    a.main-link {
      padding-left: 1rem;
    }
  }
`;

const Container = styled.li<ContainerProps>`
  background-color: ${(props) =>
    props.toggleDropdown ? props.theme.colors.idataDarkBlue : "transparent"};
  font-size: 2rem;
  white-space: nowrap;
  position: relative;

  &:not(:last-child) {
    padding: 0 0.75rem;
  }

  ${(props) => (props.accountItem ? accountItemStyles : "")};

  @media screen and (max-width: ${(props) => props.theme.bp.xl}px) {
    font-size: 1.6rem;
  }
`;

const ItemLink = styled(Link)<{ $underline: boolean }>`
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 5px;

  &:hover {
    background-color: ${(props) => props.theme.colors.idataDarkBlue};
    color: white;

    ${({ $underline }) =>
      $underline &&
      `
    color: #5DC097;
  `}
  }

  ${({ $underline }) =>
    $underline &&
    `
    color: #5DC097;
  `}
`;

const ExtItemLink = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 5px;

  &:hover {
    background-color: ${(props) => props.theme.colors.idataDarkBlue};
    color: white;
  }
`;

const DownArrow = styled.img`
  margin: auto;
  width: 2rem;
`;

const ProfilePicture = styled.img`
  width: 4rem;
  margin-right: 1rem;
`;

const Dropdown = styled.ul<ContainerProps>`
  background-color: ${(props) => props.theme.colors.idataDarkBlue};
  display: ${(props) => (props.toggleDropdown ? "block" : "none")};
  padding: 2rem 0;
  position: absolute;
  top: 80px;
  width: 19rem;
`;

const DropdownItem = styled(Link)`
  color: #fff;
  display: block;
  padding: 0.5rem 1.5rem;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const TranslateDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 0 2rem;
`;
export default MainMenuItem;
