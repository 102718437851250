const imgURL = process.env.REACT_APP_S3_BUCKET_URL;

export const generateReportUrl = (title: string) => {
  const raw = title.toLowerCase().replace(/,|\./g, "");
  const url = raw.replace(/\s|\\|\//g, "-");
  return url;
};

export const addImagePlaceholders = (div, id) => {
  const charts: any = div.querySelectorAll(".lazy");
  for (let j = 0; j < charts.length; j++) {
    if (charts[j].src.includes("placeholder")) {
      if (charts[j].dataset.original) {
        const file = charts[j].dataset.original;
        const folder = file.replace(/\.\d+\.\w+/gi, "");
        const path = `${imgURL}/${id}/${folder}/${file}`;
        charts[j].src = path;
      } else {
        const placeholder = `${imgURL}/ChartPlaceholder.png`;
        charts[j].src = placeholder;
      }
    }
  }
};

export const toggleTablesOnly = (num: string, tablesOnly: boolean) => {
  const reportContent: Element = document.querySelectorAll(
    `div#reportContent > div > div.chapter-${num}-content`
  )[0];

  if (reportContent) {
    const elements: HTMLCollection = reportContent.children;
    const tables: NodeListOf<HTMLTableElement> = reportContent.querySelectorAll(
      "table"
    );

    Array.from(elements).forEach((element: Element) => {
      handleVisibility(element);
    });

    // Include caption with table
    for (let i = 0; i < tables.length; i++) {
      const caption = tables[i].parentElement?.previousSibling as HTMLElement;
      // If previous sibling, which is the caption in most cases, is empty,
      // then there might be a heading before the empty previous sibling
      if (caption && caption.innerText.length === 0) {
        const previousNode = caption.previousSibling;
        if (previousNode && previousNode.nodeName === "H5") {
          const previousElement = previousNode as HTMLElement;
          previousElement.classList.remove("tables-only-hide-all");
        }
      }

      // Otherwise, use the previous sibling as the caption
      if (caption) caption.classList.remove("tables-only-hide-all");
    }
  }

  function handleVisibility(element: Element) {
    if (tablesOnly) {
      if (element.children.length > 1) {
        Array.from(element.children).forEach((grandchildren: Element) => {
          if (grandchildren.textContent?.startsWith("Figure")) {
            return;
          }
          if (grandchildren.tagName.toLowerCase() !== "table")
            grandchildren.classList.add("tables-only-hide-all");
        });
      } else {
        if (element.textContent?.startsWith("Figure")) {
          return;
        }
        if (element.tagName.toLowerCase() !== "table")
          element.classList.add("tables-only-hide-all");
      }
    } else {
      if (element.children.length > 1) {
        Array.from(element.children).forEach((grandchildren: Element) => {
          if (grandchildren.tagName.toLowerCase() !== "table")
            grandchildren.classList.remove("tables-only-hide-all");
        });
      } else {
        if (element.tagName.toLowerCase() !== "table")
          element.classList.remove("tables-only-hide-all");
      }
    }
  }
};

export const calculateTotalReports = (catalog) => {
  return catalog.categories.reduce((acc, cat) => {
    return (acc += cat.sub_categories.reduce((acc, sub) => {
      return (acc += sub.collections.length);
    }, 0));
  }, 0);
};

// const styleMarketShareTable = (table: HTMLTableElement) => {
//   table.classList.add("market-share");
// };
const styleMarketsCoveredTable = (table: HTMLTableElement) => {
  table.classList.add("markets-covered");

  // Go through all table's cells
  for (let i = 0, row; (row = table.rows[i]); i++) {
    for (let j = 0, col; (col = row.cells[j]); j++) {
      if (i === 0) {
        col.classList.add("table-heading");
      }

      // (By) Device Type...
      if (col.innerText.includes("Type") || col.innerText.includes("By")) {
        col.classList.add("table-subheading");
      }
    }
  }

  // Source
  table.rows[table.rows.length - 1].classList.add("table-source");
};

// const styleDeviceRecallTable = (table: HTMLTableElement) => {
//   table.classList.add("recall-title");

//   // Go through all table's cells
//   for (let i = 0, row; (row = table.rows[i]); i++) {
//     for (let j = 0, col; (col = row.cells[j]); j++) {
//       if (i == 0) {
//         if (j == 0) {
//           // Date
//           col.classList.add("table-heading");
//         }

//         if (j == 1) {
//           // Recall Title, Reason for Recall, Product(s) Affected, Links
//           col.classList.add("table-subheading");
//         }
//       }

//       if ((i == 1 || i == 2 || i == 3) && j == 0) {
//         col.classList.add("table-subheading");
//       }
//     }
//   }
// };

const styleClinicalTrialsTable = (table: HTMLTableElement) => {
  table.classList.add("clinical-trials");

  // Go through all table's cells
  for (let i = 0, row; (row = table.rows[i]); i++) {
    for (let j = 0, col; (col = row.cells[j]); j++) {
      if (j === 0) {
        // Title, Purpose, Status, Sponsor...
        col.classList.add("table-subheading");
      }
    }
  }
};

const styleMarketDriversTable = (table: HTMLTableElement) => {
  table.classList.add("market-drivers");

  // Go through all table's cells
  for (let i = 0, row; (row = table.rows[i]); i++) {
    for (let j = 0, col; (col = row.cells[j]); j++) {
      if (
        col.innerText.includes("Market Drivers") ||
        col.innerText.includes("Market Limiters")
      ) {
        col.classList.add("table-heading");
      }

      // Source
      if (col.innerText.includes("Source: iData Research Inc.")) {
        col.classList.add("table-source");
      }
    }
  }
};

const styleSWOTTable = (table: HTMLTableElement) => {
  table.classList.add("swot");

  // Go through all table's cells
  for (let i = 0, row; (row = table.rows[i]); i++) {
    for (let j = 0, col; (col = row.cells[j]); j++) {
      if (
        col.innerText === "Strengths" ||
        col.innerText === "Weaknesses" ||
        col.innerText === "Opportunities" ||
        col.innerText === "Threats"
      ) {
        col.classList.add("table-heading");
      }

      if (
        col.innerText.match("^[S]+$") ||
        col.innerText.match("^[W]+$") ||
        col.innerText.match("^[O]+$") ||
        col.innerText.match("^[T]+$")
      ) {
        col.classList.add("bigger-text");
      }

      // Source
      if (col.innerText.includes("Source: iData Research Inc.")) {
        col.classList.add("table-source");
      }
    }
  }
};

const styleDefaultTable = (table: HTMLTableElement) => {
  table.classList.add("default-table");

  // Go through all table's cells
  for (let i = 0, row; (row = table.rows[i]); i++) {
    for (let j = 0, col; (col = row.cells[j]); j++) {
      if (i === 0) {
        col.classList.add("table-heading");
      }

      // Source
      if (col.innerText.includes("Source: iData Research Inc.")) {
        col.classList.add("table-source");
      }
    }
  }
};

// Add class to table elements depending on table type
export const styleTables = () => {
  const tables = document.getElementsByTagName("table");

  for (let i = 0; i < tables.length; i++) {
    // Market Share Ranking by Segment
    // if (table.rows[0].cells[0].innerText === "Position") {
    // styleMarketShareTable(table);
    // table.classList.add("market-share");
    // } else
    if (tables[i].rows[0].cells[0]?.innerText === "Markets Covered") {
      // Markets Covered
      styleMarketsCoveredTable(tables[i]);
      // table.classList.add("markets-covered");
    } else if (tables[i].rows[0].cells[1]?.innerText === "Recall Title") {
      // Device RecallstyleDeviceRecallTable(table);
    } else if (tables[i].rows[0].cells[0]?.innerText === "Title") {
      // Clinical Trials
      styleClinicalTrialsTable(tables[i]);
    } else if (tables[i].rows[0].cells[0]?.innerText === "Market Drivers") {
      // Market Drivers
      styleMarketDriversTable(tables[i]);
    } else if (tables[i].rows[0].cells[0]?.innerText === "Strengths" ||
      tables[i].rows[0].cells[0]?.innerText === "Weaknesses" ||
      tables[i].rows[0].cells[0]?.innerText === "Opportunities" ||
      tables[i].rows[0].cells[0]?.innerText === "Threats") {
      // SWOT
      styleSWOTTable(tables[i]);
    } else if (tables[i].rows.length !== 1) {
      // only one row
      styleDefaultTable(tables[i]);
    }
  }
};
