import React from "react";
import styled from "styled-components";
import { useAuth } from "contexts/AuthContext";

import Logo from "./ChildComponents/Logo";
import MainMenu from "./ChildComponents/MainMenu/MainMenu";
import MobileMenu from "./ChildComponents/MobileMenu/MobileMenu";
import Searchbar from "./ChildComponents/Searchbar";
import ExpiryNotification from "./ChildComponents/ExpiryNotification";
import RecommendBar from "components/Common/misc/RecommendBar";
import Notification from "components/Common/misc/Notification";
import RequireCookies from "components/Common/misc/RequireCookies";

const Header: React.FC = () => {
  const { currentUser } = useAuth();

  return (
    <>
      <Wrapper id={"Header"}>
        <Container>
          <MobileMenu />
          <Logo />
          <Searchbar hidden={!currentUser} />
          <MainMenu />
        </Container>
        <div>
          {currentUser && currentUser.isAuthenticated ? (
            <ExpiryNotification />
          ) : null}
        </div>
      </Wrapper>

      <Notification />
      <RequireCookies />
      <RecommendBar />
    </>
  );
};

const Wrapper = styled.div`
  /* height: 8vh; */
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.idataDarkBlue};
  box-shadow: 1rem 0rem 2rem 1rem rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  // grid-template-columns: 25rem minmax(22rem, auto) auto;
  padding: 0 2rem;
  position: relative;
  z-index: 999;
  height: 100%;

  @media screen and (max-width: 1290px) {
    display: flex;
    justify-content: flex-start;
  }
`;

export default Header;
