import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import searchIcon from "assets/icons/icon-search-blue.svg";
import closeIcon from "assets/icons/close-icon-blue.svg";
import { RootState } from "redux/reducers";
import { searchReport, resetReportSearch } from "redux/actions/report";
import { clearErrors } from "redux/actions/errors";
import { useQuery } from "utils/search.utils";

const ReportSearch = () => {
  const dispatch = useDispatch();
  const term = useQuery().get("term");
  const { report, errors } = useSelector((state: RootState) => state);
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    if (term) {
      setQuery(term);
    }
  }, [term]);

  const useReset = (resetReportSearch) => {
    useEffect(() => {
      dispatch(resetReportSearch(report));
    }, [resetReportSearch]);
  };

  useReset(resetReportSearch);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());
    if (!query) return;

    dispatch(
      searchReport(report.reportContentData, query, report.search.query)
    );
  };

  const handleReset = async () => {
    setQuery("");
    dispatch(await resetReportSearch(report));
  };

  return (
    <Container autoComplete="off" onSubmit={handleSubmit}>
      <Input
        type="text"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        placeholder="Search within report..."
        size={15}
        // disabled={report.search.query}
      />
      {!report.search.query ? (
        <SearchButton type="submit">
          <img src={searchIcon} alt="search glass" />
        </SearchButton>
      ) : (
        <SearchButton type="reset" onClick={handleReset}>
          <img src={closeIcon} alt="close icon" />
        </SearchButton>
      )}
      <Error>{errors.search}</Error>
    </Container>
  );
};

const Container = styled.form`
  border: 1px solid ${(props) => props.theme.colors.idataBlue};
  border-radius: 5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  height: 5rem;
  // flex: 2 1 0;
`;

const Input = styled.input`
  background-color: transparent;
  color: ${(props) => props.theme.colors.idataBlue};
  font-size: 1.8rem;
  height: 6rem;
  flex: 2 1 0;

  &::placeholder {
    color: ${(props) => props.theme.colors.idataBlue};
  }

  @media screen and (max-width: 650px) {
    font-size: 1.6rem;
  }
`;

const SearchButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  height: 4rem;
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
`;

export default ReportSearch;
