import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { generateReportUrl } from "utils/report.utils";
import { ReportMeta } from "types/reports";

import FavoriteButton from "components/Common/buttons/FavoriteButton";
import ManageRecButton from "components/Common/buttons/ManageRecButton";

type CardProps = {
  report: ReportMeta;
};

const ReportCard: React.FC<CardProps> = ({ report }) => {
  if (!report.report_name) return null;
  const url = generateReportUrl(report.report_name);

  return (
    <Container>
      <Link to={`/reports/${report.report_id}/${url}/1`} target="_blank">
        <ReportName>{report.report_name}</ReportName>
      </Link>
      <TwoColumnContainer>
        <YearRegionContainer>
          <div>{report.report_year}</div>
          <div>
            <div>{report.region}</div>
            {/* <div>{report.region}</div> */}
          </div>
        </YearRegionContainer>
        <FavRecContainer>
          <FavoriteButton
            reportId={report.report_id}
            c_report_id={report.c_report_id}
            medCore={report.c_report_id ? true : false}
          />
          <ManageRecButton reportId={report.report_id} />
        </FavRecContainer>
      </TwoColumnContainer>
      <Description>{report.description}</Description>
    </Container>
  );
};

const Container = styled.div`
  /* display: grid;
  grid-template-columns: 6rem minmax(10rem, auto) auto min-content;
  grid-gap: 2rem; */
  padding: 1.5rem;
  max-width: 110rem;
  margin: 0 auto;
  background-color: white;
  /* height: 21rem; */

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const YearRegionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem 0;
`;

const ReportName = styled.div`
  font-size: 2.1rem;
`;

const Description = styled.p`
  // Show only 3 lines of text
  // https://stackoverflow.com/a/13924997
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  margin: 0;
  font-size: 1.65rem;
`;

const FavRecContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
`;

const TwoColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ReportCard;
