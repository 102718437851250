import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "redux/reducers";
import { toggleRecommending, updateReportIdRecFor } from "redux/actions/admin";

import StandardButton from "components/Common/buttons/StandardButton";

const RecModeButton: React.FC<{ hidden: boolean }> = ({ hidden }) => {
  const dispatch = useDispatch();

  const { reportMeta } = useSelector((state: RootState) => state.report);

  const handleClick = () => {
    dispatch(toggleRecommending());
    dispatch(updateReportIdRecFor(parseInt(reportMeta.report_id)));
  };

  if (hidden) {
    return <></>;
  } else {
    return (
      <CustomButton
        type="button"
        size="xs"
        color="green"
        outline={true}
        onClick={handleClick}
      >
        +
      </CustomButton>
    );
  }
};

const CustomButton = styled(StandardButton)`
  margin-left: 2rem;
`;

export default RecModeButton;
