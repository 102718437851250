import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

import { NewsItem as NewsItemType } from "types";
import NewsItem from "./ChildComponents/NewsItem";
import Tip from "components/Common/misc/Tip";

import { NEWS_FEED as newsFeedTip } from "data/tips";
import { getAutoScroll, setAutoScroll } from "../../../../../../firebase";
import { useAuth } from "contexts/AuthContext";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

// https://www.sitepoint.com/community/t/auto-scrolling-a-div-with-overflow-scroll-auto/2291/3
let scrollRate = 100;
let div, scrollInterval;
let reachedMaxScroll = false;
const divId = "container";

let prevScrollTop = 0;

function scrollDivInit() {
  div = document.getElementById(divId);
  div.scrollTop = 0;

  scrollInterval = setInterval(scrollDiv, scrollRate);
}

function scrollDiv() {
  if (!reachedMaxScroll) {
    div.scrollTop = prevScrollTop;
    prevScrollTop++;

    reachedMaxScroll = div.scrollTop >= div.scrollHeight - div.offsetHeight;
  } else {
    reachedMaxScroll = div.scrollTop === 0 ? false : true;

    div.scrollTop = prevScrollTop;
    prevScrollTop--;
  }
  return;
}

function pauseDiv() {
  clearInterval(scrollInterval);
}

function resumeDiv() {
  div = document.getElementById(divId);
  prevScrollTop = div.scrollTop;
  scrollInterval = setInterval(scrollDiv, scrollRate);
}

const NewsFeed = () => {
  const { report } = useSelector((state: RootState) => state);
  const { currentUser } = useAuth();
  const [data, setData] = useState<NewsItemType[]>([]);
  const [scroll, setScroll] = useState<boolean>(false);

  useEffect(() => {
    axios.get(`/news?sub_category_id=${report.reportMeta.sub_category_id}`)
      .then((response) => {
        const seen = new Set();
        const uniqueNews = response.data.filter(item => {
          const duplicate = seen.has(item.title);
          seen.add(item.title);
          return !duplicate;
        });

        if (uniqueNews.length > 0) {
          setData(uniqueNews);
        } 
      });

    scrollDivInit();

    getAutoScroll(currentUser).then((response) => {
      setScroll(response);
    });
  }, []);

  useEffect(() => {
    if (scroll) {
      resumeDiv();
    } else {
      pauseDiv();
    }
  }, [scroll])

  async function handleAutoScroll() {
    setScroll(!scroll);
    setAutoScroll(currentUser, !scroll);
  }

  const listItems = data.map((item) => (
    <NewsItem
      key={item.title}
      title={item.title}
      link={item.link}
      pubDate={item.pubDate}
      contentSnippet={item.contentSnippet}
    />
  ));

  return (
    <Container>
      <Header>
        News Feed
        <Tip text={newsFeedTip} bottom={-85} right={-35} width={165} />
        <div>
          <label htmlFor="auto-scroll" onClick={handleAutoScroll}>Auto-scroll</label>
          <input type="checkbox" name="auto-scroll" onChange={handleAutoScroll} defaultChecked={scroll} />
        </div>
      </Header>

      <List id="container">
        {listItems}
      </List>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  margin: 1rem 0;
  height: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  position: sticky;
  top: 0;
  z-index: 10;
  margin-bottom: 1rem;
  
  background-color: ${(props) => props.theme.colors.grey};
  border: 0;
  border-radius: 5rem;
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 2.5rem;
  padding: 1rem 2rem;
  height: 5rem;

  label {
    font-size: 2rem;
  }
`;

const List = styled.div`
  overflow-y: scroll;
`;

export default NewsFeed;
