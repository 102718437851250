import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import parse, { domToReact, HTMLReactParserOptions } from "html-react-parser";
import Modal from "react-modal";
import styled from "styled-components";
import { utils, writeFileXLSX } from "xlsx";

import { ChartModalStyles } from "styles/modals";
import { getScrollPositions } from "utils/search.utils";

import { RootState } from "redux/reducers";
import { setScrollPositions, setCurrentChapter } from "redux/actions/report";
import { setCustomCurrentChapter } from "redux/actions/custom-report";

import {
  addImagePlaceholders,
  toggleTablesOnly,
  styleTables,
} from "utils/report.utils";
import Tip from "components/Common/misc/Tip";
import { CHART as chartTip } from "data/tips";

import closeIcon from "assets/icons/close-black.svg";

type ContentProps = { 
  contentData: any;
  reportType: "regular" | "custom";
  tablesOnly: boolean;
 };

Modal.setAppElement("#root");

const ReportContent: React.FC<ContentProps> = ({ contentData, reportType, tablesOnly }) => {
  const dispatch = useDispatch();
  const idRef = useRef(0);
  const { chapter = "1"} = useParams<{ chapter: string }>();
  const { search } = useSelector((state: RootState) => state.report);
  const selectedChapter = contentData.find(
    (c) => {
      idRef.current = c.report_id;
      if (reportType === "regular") {
        return c.chapter_number === parseInt(chapter);
      } else if (reportType === "custom") {
        return c.custom_order === parseInt(chapter);
      }
    }
  );

  const [toggleModal, setToggleModal] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  let tableIdNum: number = -1;

  useEffect(() => {
    if (reportType === "regular") {
      dispatch(setCurrentChapter(parseInt(chapter)));
    } else if (reportType === "custom") {
      dispatch(setCustomCurrentChapter(parseInt(chapter)));
    }

    const div = document.getElementById("reportContent");
     
    // image with the word "Chart" in it, which doesn't seem to show up
    addImagePlaceholders(div, idRef.current); 

    if (search.query) {
      const scrollPositions = getScrollPositions().sort((a, b) => a - b);

      dispatch(setScrollPositions(scrollPositions));
    }

    // add handleClick to all charts
    const reportContent = document.getElementById("reportContent");
    if (reportContent) {
      let images = reportContent.getElementsByTagName("img");

      for (let i = 0; i < images.length; i++) {
        if (images[i].className !== "tip") {
          images[i].addEventListener(
            "click",
            function (e) {
              handleClick(e);
            },
            false
          );
        }
      }
    }

  }, [chapter, dispatch, idRef.current, search.query, tablesOnly]);

  useEffect(() => {
    styleTables();
  }, [idRef.current, chapter]);

  useEffect(() => {
    toggleTablesOnly(chapter, tablesOnly); 
  }, [idRef.current, chapter, tablesOnly]);

  const handleClick = (e) => {
    setToggleModal(true);
    setImgSrc(e.target.src);
  };

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode.name && domNode.children && domNode.attribs) {
        if (domNode.name === "table") {
          tableIdNum++;

          let tableId = `table-${tableIdNum}`;

          return (
            <React.Fragment>
              <button className="excelBtn" onClick={() => {
                /* Create worksheet from HTML DOM TABLE */
                var wb = utils.table_to_book(document.getElementById(tableId));
                /* Export to file (start a download) */
                writeFileXLSX(wb, `iData-Online-Report-${idRef.current}-Chapter-${selectedChapter.chapter_number}-${tableId}.xlsx`);
              }}>Export Table</button>
              <table id={tableId}>
                {domToReact(domNode.children, options)}
              </table>
            </React.Fragment>
          );
        }
      }

      if (domNode && domNode.attribs && domNode.name === "img") {
        const imgURL = process.env.REACT_APP_S3_BUCKET_URL;
        const file = domNode.attribs["data-original"];

        if (file) {
          // For images with these attributes:
          // <img src="images/g-placeholder.jpg" alt="report chart" data-original="usars21_rpt-press-ready-ido.001.jpg"
          // class="report-chart lazy" width="698" height="395">
          const folder = file.replace(/\.\d+\.\w+/gi, "");
          const path = `${imgURL}/${idRef.current}/${folder}/${file}`;

          return (
            <React.Fragment>
              <img
                src={path}
                data-original={domNode.attribs["data-original"]}
                alt=""
                width={domNode.attribs.width}
                height={domNode.attribs.height}
              />
              <TipContainer>
                <Tip text={chartTip} bottom={-75} right={-13} width={160} />
              </TipContainer>
            </React.Fragment>
          );
        } else {
          // For images with these attributes:
          // <img src="http://cdn.idataonline.net.s3-us-west-2.amazonaws.com/1288/usars21_rpt-press-ready-ido/usars21_rpt-press-ready-ido.006.jpg"
          // alt="report chart" class="report-chart" width="698" height="448">
          const src = domNode.attribs.src;
          const file = src.substring(src.lastIndexOf("/") + 1);
          const folder = file.replace(/\.\d+\.\w+/gi, "");
          const path = `${imgURL}/${idRef.current}/${folder}/${file}`;
          return (
            <React.Fragment>
              <img
                src={path}
                data-original={domNode.attribs["data-original"]}
                alt=""
                width={domNode.attribs.width}
                height={domNode.attribs.height}
              />
              <TipContainer>
                <Tip text={chartTip} bottom={-75} right={-110} width={236} />
              </TipContainer>
            </React.Fragment>
          );
        }
      }

      if (
        domNode &&
        domNode.attribs &&
        domNode.children &&
        domNode.name === "a"
      ) {
        if (
          domNode.attribs.id &&
          domNode.attribs.id.startsWith("_Toc") &&
          domNode.children.length > 0
        ) {
          let caption = "";

          domNode.children.forEach((child) => {
            if (child.children) {
              return child.children.forEach((element) => {
                if (element.data) caption += element.data;
              });
            } else {
              if (child.data) caption += child.data;
            }
          });

          return <>{caption.replace("?", "-")}</>;
        }
      }
    },
    trim: true,
  };

  return (
    <Container id="reportContent">
      {parse(selectedChapter.chapter_content, options)}

      <Modal
        onRequestClose={() => setToggleModal(false)}
        isOpen={toggleModal}
        style={ChartModalStyles}
      >
        <Chart>
          <button
            onClick={() => {
              setToggleModal(false);
            }}
          >
            <img src={closeIcon} alt="close icon" />
          </button>
          <img src={imgSrc} alt="" />
        </Chart>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  font-size: 1.8rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  ul,
  p {
    max-width: 70rem;
    margin: 2rem auto;
  }

  h3 {
    color: ${(props) => props.theme.colors.idataBlue};
    font-size: 3.5rem;
    margin-top: 0;
  }

  img {
    height: 100%;
    width: 100%;
  }

  ul {
    list-style: inside;
  }

  table {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    color: ${(props) => props.theme.colors.idataDarkGrey};
    margin-bottom: 5rem;
    table-layout: auto;
    text-align: left;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.darkGrey};
    word-break: break-word;

    td {
      border: 1px solid ${(props) => props.theme.colors.darkGrey};
    }

    th,
    td {
      padding: 5px;
    }

    p {
      margin: 0;
    }
  }
`;

const Chart = styled.div`
  width: 100%;
  height: 100%;

  button {
    float: right;
  }

  img {
    display: block;
    margin: 0 auto;
    height: 100%;
  }
`;

const TipContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  img {
    height: auto;
    width: auto;
  }
`;

export default ReportContent;
