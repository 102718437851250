import React from "react";
import { Route } from "react-router-dom";
import styled from "styled-components";

import RecoveryForm from "./ChildComponents/RecoveryForm";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const PasswordRecovery: React.FC = () => (
  <>
    <Header />
    <Container>
      <InnerContainer>
        <Heading>
          <PageTitle>Password Recovery</PageTitle>
        </Heading>
        <RecoveryForm />
      </InnerContainer>
    </Container>
    <Footer />
  </>
);

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  color: #000;
  min-height: 100vh;
  width: 100%;
`;

const InnerContainer = styled.div`
  max-width: ${(props) => props.theme.bp.xxl}px;
  margin: auto;
  padding: 5rem;
  padding-top: 3rem;
  width: 100%;
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    padding: 2rem;
  }
`;

const Heading = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.darkGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding: 1rem 0;
`;

const PageTitle = styled.h1`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 4rem;
  font-weight: 400;
`;

export default PasswordRecovery;
