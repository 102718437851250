import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import Modal from "react-modal";

import { useAuth } from "contexts/AuthContext";
import { modalStyles } from "styles/modals";
import { generateReportUrl } from "utils/report.utils";
import { RootState } from "redux/reducers";
import CustomReportSearch from "./CustomReportSearch";
import FavoriteButton from "components/Common/buttons/FavoriteButton";
import DashboardButton from "components/Common/buttons/DashboardButton";
import DashboardSettings from "./DashboardSettings";

import arrowLeftIcon from "assets/icons/arrow-left-long.svg";
import { ReactComponent as LeftSidebarIcon } from "assets/icons/interface-layout-left-sidebar-icon.svg";
import { ReactComponent as RightSidebarIcon } from "assets/icons/interface-layout-right-sidebar-icon.svg";

Modal.setAppElement("#root");

type ToggleLeftProps = {
  toggleLeftSide?: boolean;
};
type ToggleRightProps = {
  toggleRightSide?: boolean;
};
type T = {
  toggleLeftSide: boolean;
  setToggleLeftSide: Function;
  toggleRightSide: boolean;
  setToggleRightSide: Function;
};

const CustomReportHeader: React.FC<T> = ({
  toggleLeftSide,
  setToggleLeftSide,
  toggleRightSide,
  setToggleRightSide,
}) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const { reportData } = useSelector((state: RootState) => state.customReport);
  const [showDashboardBtn, setShowDashboardBtn] = useState<boolean>(false);
  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    // Find out if there is a global dashboard associated with this MedCore or not
    axios
      .get(`/reports/report-info/${reportData.report_id}`)
      .then((res) => {
        if (res.data.global_dashboard === 1) {
          setShowDashboardBtn(true);
          // console.log("A global dashboard is associated with this MedCore - " + reportData.c_report_id);
        }
      })
      .catch((error) => console.log(error));
  }, [reportData]);

  return (
    <Container id={"CustomReportHeader"}>
      <LeftSection>
        <LeftSidebarButton onClick={() => setToggleLeftSide(!toggleLeftSide)}
          toggleLeftSide={toggleLeftSide}>
          <LeftSidebarIcon />
        </LeftSidebarButton>

        <BackLink to={location.state ? "/catalog/search" : "/catalog"}>
          <img src={arrowLeftIcon} alt="back icon" />
          <div>{location.state ? "Results" : "Catalog"}</div>
        </BackLink>
      </LeftSection>

      <HeadingDiv>
        <Heading>{reportData.c_report_name}</Heading>
      </HeadingDiv>

      <RightSection>
        <DashboardSettingsButton
          hidden={currentUser.email === "support@idataresearch.net"}
          onClick={() => setToggleModal(true)}
        >
          Dashboard Settings
        </DashboardSettingsButton>

        <Modal
          onRequestClose={() => setToggleModal(false)}
          isOpen={toggleModal}
          contentLabel="Ask an Analyst"
          style={modalStyles}
        >
          <DashboardSettings setToggleModal={setToggleModal} />
        </Modal>

        <DashboardButton
          global_dashboard={+showDashboardBtn}
          c_report_id={reportData.c_report_id}
          id={reportData.report_id}
          url={generateReportUrl(reportData.c_report_name)}
          subscribed={true}
        />

        <div hidden={reportData.medcore === 1 ? false : true}>
          <FavoriteButton
            reportId={0}
            c_report_id={reportData.c_report_id}
            medCore={reportData.c_report_id ? true : false}
          />
        </div>

        <CustomReportSearch />

        <RightSidebarButton onClick={() => setToggleRightSide(!toggleRightSide)}
          toggleRightSide={toggleRightSide}>
          <RightSidebarIcon />
        </RightSidebarButton>
      </RightSection>

      <BottomSection>
        <LeftSidebarButton onClick={() => setToggleLeftSide(!toggleLeftSide)}
          toggleLeftSide={toggleLeftSide}>
          <LeftSidebarIcon />
        </LeftSidebarButton>

        <MiddleSection>
          <ButtonsDiv>
            <BackLink to={location.state ? "/catalog/search" : "/catalog"}>
              <img src={arrowLeftIcon} alt="back icon" />
              <div>{location.state ? "Results" : "Catalog"}</div>
            </BackLink>

            <DashboardSettingsButton
              hidden={currentUser.email === "support@idataresearch.net"}
              onClick={() => setToggleModal(true)}
            >
              Dashboard Settings
            </DashboardSettingsButton>

            <Modal
              onRequestClose={() => setToggleModal(false)}
              isOpen={toggleModal}
              contentLabel="Ask an Analyst"
              style={modalStyles}
            >
              <DashboardSettings setToggleModal={setToggleModal} />
            </Modal>

            <DashboardButton
              global_dashboard={+showDashboardBtn}
              c_report_id={reportData.c_report_id}
              id={reportData.report_id}
              url={generateReportUrl(reportData.c_report_name)}
              subscribed={true}
            />

            <div hidden={reportData.medcore === 1 ? false : true}>
              <FavoriteButton
                reportId={0}
                c_report_id={reportData.c_report_id}
                medCore={reportData.c_report_id ? true : false}
              />
            </div>
          </ButtonsDiv>

          <CustomReportSearch />

        </MiddleSection>

        <RightSidebarButton onClick={() => setToggleRightSide(!toggleRightSide)}
          toggleRightSide={toggleRightSide}>
          <RightSidebarIcon />
        </RightSidebarButton>
      </BottomSection>
    </Container>
  );
};

const Container = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.darkGrey};
  background-color: #f6f8f9;

  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  padding: 1rem;
  z-index: 300;

  @media screen and (max-width: 1150px) {
    display: block;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: 400;
  margin: 0;
`;

const HeadingDiv = styled.div`
  margin: 0 1rem;

  @media screen and (max-width: 1150px) {
    margin: 0;
    text-align: center;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: 1rem;
    border-bottom: 2px solid ${(props) => props.theme.colors.darkGrey};
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;

  button, form {
    margin-left: 1rem;
  }

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

const BottomSection = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1150px) {
    display: flex;
  }
`;

const MiddleSection = styled.div`
  display: flex;
  align-items: center;

  form {
    margin-right: 1rem;
  }

  @media screen and (max-width: 700px) {
    display: block;
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem;
  
  button {
    margin: 0 1rem;
  }
  
  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
    justify-content: space-evenly;

    button {
      margin-bottom: 1rem;
    }
  }
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0 1rem;

  img {
    margin-right: 1rem;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const DashboardSettingsButton = styled.button`
  background-color: ${(props) => props.theme.colors.idataBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.8rem;
  padding: 1rem 2rem;
  display: ${(props) => (props.hidden ? "flex" : "none")};

  &:hover {
    opacity: 0.8;
  }
`;

const LeftSidebarButton = styled.button<ToggleLeftProps>`
  background-color: ${(props) => (props.toggleLeftSide ?
    props.theme.colors.idataBlue : props.theme.colors.darkGrey)};
  padding: 1rem;

  svg {
    width: 3rem;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(52deg) brightness(101%) contrast(102%);
  }
`;

const RightSidebarButton = styled.button<ToggleRightProps>`
  background-color: ${(props) => (props.toggleRightSide ?
    props.theme.colors.idataBlue : props.theme.colors.darkGrey)};
  padding: 1rem;

  svg {
    width: 3rem;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(52deg) brightness(101%) contrast(102%);
  }
`;

export default CustomReportHeader;
