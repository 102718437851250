import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Modal from "react-modal";

import { generatePageTitle } from "utils/general.utils";
import { useAuth } from "contexts/AuthContext";

import {
  fetchCatalogData,
  setFilters,
  resetFilters,
} from "redux/actions/catalog";
import { RootState } from "redux/reducers";

import Search from "./ChildComponents/Search/Search";
import Spinner from "components/Common/misc/Spinner";
import { reportFilters, catalogMenu } from "data/menus/catalogMenu";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import CatalogSkeleton from "./CatalogSkeleton";
import { fetchSubscriptions } from "redux/actions/subscriptions";
import { fetchCustomReportMetadata } from "redux/actions/custom-report";
import { getUsergroups } from "redux/actions/admin";

Modal.setAppElement("#root");
const ReportList = React.lazy(
  () => import("./ChildComponents/Preview/ReportList")
);

const Catalog = () => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const { catalog } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(fetchCatalogData(setLoading));

    if (currentUser.email !== "support@idataresearch.net" &&
      currentUser.email !== "marketing@idataresearch.net") {
      dispatch(fetchSubscriptions(currentUser.usergroupData.id));
    }

    if (currentUser.usergroupData.headquarter) {
      dispatch(
        fetchCustomReportMetadata(
          currentUser.usergroupData.subsidiaryCompanies,
          undefined
        )
      );
    } else {
      dispatch(
        fetchCustomReportMetadata([currentUser.usergroupData.id], undefined)
      );
    }
  }, []);

  useEffect(() => {
    dispatch(resetFilters());
  }, [
    catalog.filters.filter,
    catalog?.categories?.length,
    dispatch,
  ]);

  // Change filter based on pathname
  useEffect(() => {
    switch (location.pathname) {
      case "/catalog/subscriptions":
        dispatch(setFilters("filter", "Subscriptions"));
        dispatch(setFilters("subscribed", true));
        document.title = generatePageTitle("Subscriptions");
        break;
      case "/catalog/favorites":
        dispatch(setFilters("filter", "Favorites"));
        dispatch(setFilters("subscribed", false));
        document.title = generatePageTitle("Favorites");
        break;
      case "/catalog/custom-reports":
        dispatch(setFilters("filter", "Custom Reports"));
        dispatch(setFilters("subscribed", false));
        document.title = generatePageTitle("Custom Reports");

        if (currentUser.email === "support@idataresearch.net") {
          dispatch(getUsergroups());
        }
        break;
      default:
        dispatch(setFilters("filter", "All Reports"));
        dispatch(setFilters("subscribed", false));
        document.title = generatePageTitle("All Reports");
        break;
    }
  }, [dispatch, location.pathname]);

  if (loading) {
    return (
      <>
        <Header />
        <Spinner />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <Container>
          <Routes>
            <Route path="/search" element={<Search />} />
            <Route path="/subscriptions" element={
              <CatalogSkeleton
                menu={catalogMenu}
                filters={reportFilters}
                list={<ReportList />}
                hideFilters={false}
                parent={"catalog"}
                sectionName={"Subscriptions"}
              />} />
            <Route path="/favorites" element={
              <CatalogSkeleton
                menu={catalogMenu}
                filters={reportFilters}
                list={<ReportList />}
                hideFilters={false}
                parent={"catalog"}
                sectionName={"Favorites"}
              />} />
            <Route path="/custom-reports" element={
              <CatalogSkeleton
                menu={catalogMenu}
                filters={reportFilters}
                list={<ReportList />}
                hideFilters={
                  currentUser.email === "support@idataresearch.net"
                    ? false
                    : true
                }
                parent={"catalog"}
                sectionName={"Custom Reports"}
              />} />
            <Route path="/all-reports" element={
              <CatalogSkeleton
                menu={catalogMenu}
                filters={reportFilters}
                list={<ReportList />}
                hideFilters={false}
                parent={"catalog"}
                sectionName={"Reports Catalog"}
              />} />
          </Routes>
        </Container>
        <Footer />
      </>
    );
  }
};

const Container = styled.div`
  background-color: #f6f8f9;
  color: #000;
  min-height: 100vh;
  width: 100%;
`;

export default Catalog;
