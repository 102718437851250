import React from "react";
import styled from "styled-components";

import bookmarks from "assets/images/custom-reports-guide-bookmarks.png";
import createAdd from "assets/images/custom-reports-guide-create-add.png";
import share from "assets/images/custom-reports-guide-share.png";

const CustomReportsGuide: React.FC = () => {
  return (
    <React.Fragment>
      <NoCustomReports>You have no Custom Reports to view</NoCustomReports>
      <Container>
        <h1>About Custom Reports</h1>
        <div>
          <p>
            A Custom Report is a collection of report Bookmarks from any report
          </p>
          <img src={bookmarks} alt="Custom Reports Bookmarks" />
        </div>
        <div>
          <p>
            Everyone can create up to 10 Custom Reports each with up to 10
            Bookmarks
          </p>
        </div>
        <div>
          <p>
            Visit any subscribed report to create a Custom Report or add to an
            existing one
          </p>
          <img src={createAdd} alt="create or add to Custom Report" />
        </div>
        <div>
          <p>
            Custom Reports can be shared among the members of your company, but
            only the creator may edit them
          </p>
          <img src={share} alt="share Custom Report modal" />
        </div>
      </Container>
    </React.Fragment>
  );
};

const Container = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  background-color: white;
  padding: 3rem;

  h1 {
    margin-top: 0;
  }

  div {
    margin-bottom: 5rem;
  }
`;

const NoCustomReports = styled.p`
  text-align: center;
  font-style: italic;
  margin-bottom: 5rem;
`;

export default CustomReportsGuide;
