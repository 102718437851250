import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import optionsIcon from "assets/icons/options-icon.svg";
import { RootState } from "redux/reducers";
import { removeCustomReport } from "redux/actions/custom-report";

import EditModal from "./EditModal";
import ShareModal from "./ShareModal";

const OptionsMenu = () => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customReport = useSelector((state: RootState) => state.customReport);
  const [toggleEditModal, setToggleEditModal] = useState<boolean>(false);
  const [toggleShareModal, setToggleShareModal] = useState<boolean>(false);

  const handleDelete = () => {
    if (
      window.confirm(
        "Are you sure you wish to delete this report? This action cannot be reversed."
      )
    ) {
      dispatch(
        removeCustomReport(
          customReport.reportData.c_report_id,
          currentUser.usergroupData.id,
          navigate
        )
      );

      // redirect to catalog
      if (navigate) {
        navigate("/");
      }
    }
  };

  return (
    customReport.reportData.c_report_name && (
      <Container>
        <MenuHeader className="menuHeader">
          <OptionsIcon src={optionsIcon} alt="bookmark icon" /> Options
        </MenuHeader>
        <ReportSubMenu>
          <SubMenuItem>
            <SubMenuButton onClick={() => setToggleEditModal(!toggleEditModal)}>
              Edit Report
            </SubMenuButton>
          </SubMenuItem>
          <SubMenuItem>
            <SubMenuButton
              onClick={() => setToggleShareModal(!toggleShareModal)}
            >
              Share With Team
            </SubMenuButton>
          </SubMenuItem>
          <SubMenuItem>
            <SubMenuButton onClick={handleDelete}>Delete</SubMenuButton>
          </SubMenuItem>
        </ReportSubMenu>
        <EditModal
          toggleEditModal={toggleEditModal}
          setToggleEditModal={setToggleEditModal}
        />
        <ShareModal
          toggleShareModal={toggleShareModal}
          setToggleShareModal={setToggleShareModal}
          customReportData={customReport.reportData}
        />
      </Container>
    )
  );
};

const Container = styled.div`
  overflow-y: auto;
  margin: 1rem 0;
`;

const ReportSubMenu = styled.ul`
  padding-right: 2rem;
`;

const MenuHeader = styled.li`
  background-color: ${(props) => props.theme.colors.grey};
  border: 0;
  border-radius: 5rem;
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 2.5rem;
  transition: all 0.2s;

  list-style-type: none;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const OptionsIcon = styled.img`
  margin-right: 5px;
`;

const SubMenuItem = styled.li`
  margin-bottom: 1rem;
`;

const SubMenuButton = styled.button`
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 1.8rem;
  transition: all 0.2s;

  &:hover {
    color: ${(props) => props.theme.colors.idataBlue};
    text-decoration: underline;
  }
`;

export default OptionsMenu;
