import { createGlobalStyle } from "styled-components";

const colors = {
  lightBlue: "#b7bdc7",
  idataBlue: "#1D83B7",
  idataDarkBlue: "#376187",
  coolBlue: "#17a1e6",
  yellow: "#EFB957",
  green: "#5DC097",
  red: "#D05F41",
  orange: "#fe811b",
  lighterGrey: "#f6f8f9",
  grey: "#e6e7e8",
  darkGrey: "#acacac",
  idataDarkGrey: "#4d4d4f",
  tableHeading: "#1f84b8",
  tableSource: "#91d4e7",
  tableSubheading: "#538ed5",
};

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%; /* 10px is 1rem */
        scroll-behavior: smooth;
    }
    
    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Roboto Condensed", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.8rem;
        scroll-behavior: smooth;
    }
      
    html,
    body,
    div#root {
        height: 100%;
        width: 100%;
    }
    
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    a {
        color: #1D83B7;
        text-decoration: none;
        transition: all 0.2s;

        &:hover {
            opacity: 0.8;
            text-decoration: none;
        }
    }

    button {
        background: transparent;
        border: none;
        border-radius: 5rem;
        color: #fff;
        cursor: pointer;
        transition: all 0.2s;

        &:active,
        &:focus {
            outline: none;
        }
    }

    input { 
        background-repeat: no-repeat;
        background-size: 2.5rem;
        border: 0;
        border-radius: 5rem;

        &:active,
        &:focus {
            outline: none;
        }
    }

    select {
        cursor: pointer;
        font-size: 1.8rem;
        padding: 5px 10px;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    input[type="checkbox"]:not([disabled]),
    input[type="radio"]:not([disabled]) {
        cursor: pointer;
    }

    input[type="file"] {
        font-size: 1.6rem;
        padding: 1rem;
    }

    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 1000px #b7bdc7 inset;
    }

    // Parsed report styling
    .report-highlight {
        background-color: yellow;
    }

    #reportContent {
        .excelBtn {
            background-color: #e6e7e8;
            color: #4d4d4f;
            display: flex;
            align-items: center;
            float: right;
            font-size: 1.8rem;
            padding: 0.5rem 2.5rem;
            margin-top: -4rem;

            @media screen and (max-width: 1035px) {
                float: none;
                margin: 0 auto 1rem;
            }

            &:hover {
                background-color: #4d4d4f;
                color: #fff;
            }

            img {
                margin-right: 5px;
            }
        }
    }

    .tables-only-hide-all {
        display: none;
    }

    .market-share {
        background-color: black;
    }

    .markets-covered {
        // background-color: red;
    }

    .recall-title {
        // background-color: green;

        .table-heading {
            background-color: ${colors.tableHeading};
            color: white;
        }

        .table-subheading {
            background-color: ${colors.tableSource};
            color: ${colors.idataDarkGrey};
        }
    }

    .clinical-trials {
        // background-color: purple;

        .table-subheading {
            background-color: ${colors.tableSource};
            color: ${colors.idataDarkGrey};
        }
    }

    .market-drivers {
        // background-color: pink;

        .table-heading {
            background-color: ${colors.tableHeading};
            color: white;
        }
    }

    .swot {
        // background-color: yellow;

        .table-heading {
            background-color: ${colors.tableHeading};
            color: white;
        }

        .table-heading p {
            margin: 0;
        }

        .bigger-text {
            font-size: 4rem;
            padding: 4rem;
        }

        ul, li {
            margin: 0;
        }
    }

    .market-products {
        background-color: gray;
    }

    .default-table {
        // background-color: cyan;
        border-spacing: 0px;

        .table-heading {
            background-color: ${colors.tableHeading};
            color: white;   
    
            p:first-of-type {
                margin: auto;
                text-align: left;
                margin-top: 2rem;
                margin-left: 0;
            }
        }
    }

    .table-subheading {
        background-color: ${colors.tableSubheading};
        color: white;   
    }

    .table-source {
        background-color: ${colors.tableSource};
        text-align: right;

        p {
            max-width: 100%;
        }
    }

    .pdf-styling {
        box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
    }

    body {
        top: 0px !important; 
    }

    .goog-logo-link {
        display:none !important;
    } 
        
    .goog-te-gadget {
        color: transparent !important;
        height: 40px;
    }
    
    .goog-te-gadget.skiptranslate span {
        display: none !important;
    } 
`;

export default GlobalStyle;
