import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "react-modal";

import leftArrowGrn from "assets/icons/arrow-left-green.svg";

import { modalStyles } from "styles/modals";
import { RootState } from "redux/reducers";

import ReportNavigator from "./ReportNavigator";
import AskAnalyst from "components/Common/forms/AskAnalyst";
import StandardButton from "../../../../Common/buttons/StandardButton";
import NewsFeed from "./NewsFeed/NewsFeed";
import RecommendedReports from "./RecommendedReports/RecommendedReports";

Modal.setAppElement("#root");

type ToggleProps = { toggleRightSide?: boolean };
type T = {
  reportId: number;
  toggleRightSide: boolean;
  setToggleRightSide: Function;
};

const ReportSidebar: React.FC<T> = ({ reportId, toggleRightSide }) => {
  const { report } = useSelector((state: RootState) => state);
  const [toggleModal, setToggleModal] = useState(false);

  return (
    <Container id="ReportSidebar" className="side-menu" toggleRightSide={toggleRightSide}>
      <Wrapper>
        <div>
          {report.search.scrollPositions.length > 0 && <ReportNavigator />}
        </div>
        <CustomButton
          type="button"
          size="lg"
          color="green"
          outline={true}
          onClick={() => setToggleModal(true)}
        >
          <img src={leftArrowGrn} alt="left arrow icon" />
          Contact Us
        </CustomButton>
        <RecommendedReports reportId={reportId} />
        <Modal
          isOpen={toggleModal}
          contentLabel="Contact Us"
          style={modalStyles}
        >
          <AskAnalyst setToggleModal={setToggleModal} />
        </Modal>
        <NewsFeed />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div<ToggleProps>`
  height: 100vh;
  position: sticky;
  top: 0;
  padding-right: 1rem;

  display: flex;
  background-color: #f6f8f9;

  > div {
    display: ${(props) => (props.toggleRightSide ? "flex" : "none")};
    width: ${(props) => (props.toggleRightSide ? "40rem" : "0rem")};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
`;

const CustomButton = styled(StandardButton)`
  margin-top: 1rem;
`;

export default ReportSidebar;
